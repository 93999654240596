<template>
  <div class="d-block d-lg-none">
    <div class="row">
      <div class="col-10 mb-0 col-sm-9 col-lg-10">
        <p class="groupMobile" style="margin-left: 9px;">Communites and groups</p>
      </div>
      <div class="col-2 col-sm-3 col-lg-2">
        <p class="closeButton" @click="GoSetting()"><font-awesome-icon :icon="['fas','times']" /></p>
      </div>
    </div>
    <div class="hr-border"></div>
  </div>
  <div class="mobileStyleDoctor">
    <div class="row">
      <div class="col-12">
        <p class="gorupstext mb-0">Based on your specialties and your location, RxIx has enrolled you into the following groups
          and communities.</p>
      </div>
    </div>
    <div class="row mt-2">
      <h5 class="subHeadingText">Doctor groups</h5>
      <div class="col-12 col-lg-4 col-sm-6 col-md-6 mt-0" v-for="practicearea in doctorPracticeArea" :key="practicearea">
        <div class="border-right-color pe-4">
        <ul>
          <li class="list-style-none row">
            <div class="col-10 mb-0 col-lg-8">{{ practicearea.practiceArea }} Doctors</div>
            <div class="col-2 col-lg-4">
                  <span class='text-red-color' >
                    <font-awesome-icon :icon="['fas', 'times-circle']" @click="showPopupPracticeArea(practicearea)" />
                  </span>

                  <div v-if="isPopupVisiblePracticeArea" class="doctorPopup">
      <div class="popup-content">
        <p>Do you want to opt out of community?</p>
        <div style="display: flex;justify-content: space-around;">
          <button @click="deletePracticeArea()" class="yes-button">Yes</button>
          <button @click="handleNo" class="no-button">No</button>
        </div>
      </div>
    </div>
  </div>
  </li>
  </ul>
    </div>
      </div>
      <div class="hr-border"></div>
      <div class="row mt-3">
        <div class="col-12">
          <h5 class="subHeadingText">Patient support groups</h5>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4 col-sm-6 col-md-6 mt-0" v-for="(patientGroup, index) in doctorPatientGroup" :key="index">
        <div class="border-right-color pe-4">
          <ul>
            <li class="list-style-none row">
                <div class="col-10 mb-0 col-lg-8">{{ patientGroup.groupname }}</div>
                <div class="col-2 col-lg-4">
                  <span class='text-red-color' >
                    <font-awesome-icon :icon="['fas', 'times-circle']" @click="showPopupPatientGroup(patientGroup)" />
                  </span>
                  <div v-if="isPopupVisiblePatientGroup" class="doctorPopup">
      <div class="popup-content">
        <p>Do you want to opt out of community?</p>
        <div style="display: flex;justify-content: space-around;">
          <button @click="deletePatientGroup()" class="yes-button">Yes</button>
          <button @click="handleNoPatientGroup" class="no-button">No</button>
        </div>
      </div>
    </div>
    </div>
    </li>
    </ul>
     </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  components: {},
  data() {
    return {
      isPopupVisiblePracticeArea: false,
      isPopupVisiblePatientGroup: false,
      currentRoute: "",
      tagId: 0,
      doctorInfo: {},
      doctorPracticeArea: [],
      doctorPatientGroup: [],
      practiceAreaData:null,
      patientGroupData:null
    };
  },
  created: function () {
    this.getDoctor()
    this.tagId = this.$route.query.id;
    this.currentRoute = this.$route.path.split('/')[2];
  },
  methods: {

    showPopupPracticeArea(practice) {
      this.practiceAreaData=practice
      this.isPopupVisiblePracticeArea = true;
    },
    closePopup() {
      this.isPopupVisiblePracticeArea = false;
    },

    handleNo() {
      this.closePopup();
    },

    showPopupPatientGroup(group) {
      this.patientGroupData =group
      this.isPopupVisiblePatientGroup = true;
    },
    closePopupPatientGroup() {
      this.isPopupVisiblePatientGroup = false;
    },

    handleNoPatientGroup() {
      this.closePopupPatientGroup();
    },

    GoSetting() {
      window.scrollTo(0, 0);
      this.$router.push(`/doctor/mobile-settings`);
    },
    getDoctor() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/hcps/${localStorage.getItem('id')}/one`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data) {
            this.doctorInfo = response.data
            if (this.doctorInfo.practiceId) {
              this.doctorPracticeArea = this.doctorInfo.practiceId
            }
            if (this.doctorInfo.doctorPatientId) {
              this.doctorPatientGroup = this.doctorInfo.doctorPatientId
            }
          }
        })
        .catch(function (error) {
          return error
        });
    },

    deletePracticeArea(){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      axios
        .delete(`${BASE_API_URL}/doctorpracticearea/${this.practiceAreaData.doctorpracticearea}/delete`)
        .then((response) => {
        if(response.data){
          this.practiceAreaData=null
          this.getDoctor();
          this.closePopup();
        }

        })
        .catch(function (error) {
          return error
        });
    },
    deletePatientGroup() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      axios
        .delete(`${BASE_API_URL}/doctorpatientgroup/${this.patientGroupData.doctorpatientgroupid}/delete`)
        .then((response) => {
        if(response.data){
          this.getDoctor();
          this.closePopupPatientGroup();
        }

        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
  }
}
</script>
<style>

.subHeadingText{
  font-size: 23px;
  }
.doctorPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.yes-button,
.no-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.yes-button {
  background-color: #38761D;
  color: #fff;
}
.no-button {
  background-color: #FF9900;
  color: #fff;
}
.list-style-none {
  list-style: none !important;
  line-height: 1.5em;
}
svg.svg-inline--fa.fa-check-circle.fa-w-16 {
  color: lightgreen;
}

.text-red-color{
  font-size:20px;
  color: #cd381c;
  cursor: pointer;
}
@media screen and (max-width:991px) {
  .subHeadingText{
  font-size: 20px;
  }
  .mobileStyleDoctor {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 6rem;
  }
  .groupMobile {
    margin-left: 20px;
    /* margin-bottom: 10px; */
    margin-top: 1rem;
    color: #000;
    font-weight: 550;
    font-size: 21px;
  }

  .gorupstext {
    margin-top: 15px;
  }
}
</style>